import React, { FC, Fragment, forwardRef } from 'react';

import { ChevronRightIcon, HeartIcon } from '@dsch/icons';
import styled from 'styled-components';

import { safeGet, useFeatureFlag } from '../../../../helpers/utils';
import LazyImage from '../../../../hooks/useLazyImage';

import { Media } from '../../../../types';
import { FIRST_BREAK_POINT } from '../cardConstants';
import { ImageWrapper } from '@dist-property-frontend/toolkit';

export type SubUnitProps = {
  title?: string;
  lineTwoInfo?: (string | undefined)[];
  lineTwoExtra?: string;
  lineThreeInfo?: (string | undefined)[];
  lineThreeImage?: string;
  lineThreeImageAlt?: string;
  lineThreeImageFallback?: string;
  seoFriendlyPath?: string;
  cardType?: string;
  icon?: string;
  media?: Media;
  loadEagerly: boolean;
  fallback?: string;
  shouldShowImage?: boolean;
  routes: any;
  showTriangle?: boolean;
  shouldOpenNewPage?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isIconActive?: boolean;
  isMobileView?: boolean;
  wrapText?: boolean;
};

export const Wrapper = styled.a<{ cardType?: string }>`
  cursor: pointer;
  position: relative;
  color: #333333;
  display: flex;
  align-items: center;
  min-height: 70px;
  background-color: #ffffff;
  position: relative;
  ${({ cardType }) =>
    cardType === 'SMALL'
      ? `
    min-height: 110px;
    padding: 16px 8px 16px 0;
    box-shadow: 0 4px 16px 0 rgba(51, 51, 51, 0.16);
  `
      : `

  &:hover {
    background: #ebebeb;
  }
  `}
`;

export const ColA = styled.div`
  flex: 0 0 auto;
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    padding-right: 8px;
  }
`;

export const ThumbnailBackdrop = styled.div`
  margin: 7px 0 7px 15px;
  height: 50px;
  width: 72px;
  background: #f4f4f4;
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    margin: 9px 0px 9px 24px;
  }
`;

export const Thumbnail = styled.picture`
  width: 64px;
  height: 56px;
  object-fit: cover;
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    width: initial;
    height: initial;
  }
`;

export const StyledCol = styled.div`
  padding-left: 16px;
`;

export const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    font-size: 14px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`;

export const CardInfo = styled.div<{ isMobileView?: boolean }>`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    margin-right: 0;
    flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
    flex-wrap: wrap;
  }
`;

export const CardInfoItem = styled.div<{
  cardType?: string;
  flex?: boolean;
  isMobileView?: boolean;
  wrapText?: boolean;
}>`
  font-size: 14px;
  color: ${({ cardType }) => (cardType === 'SMALL' ? '#333333' : '#777777')};
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ cardType }) => cardType !== 'SMALL' && 'max-width: 24ch'};
  ${({ cardType }) => cardType !== 'SMALL' && 'white-space: nowrap'};
  ${({ cardType }) => cardType === 'SMALL' && 'max-height: 40px'};
  ${({ wrapText }) =>
    wrapText && 'white-space: normal; display: block; max-width: 100%;'}

  ${({ isMobileView, cardType }) =>
    !isMobileView &&
    `
      @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
        max-width: 100%;
        white-space: normal;
        color: ${cardType === 'SMALL' ? '#333333' : '#777777'};
      }
    `}

  ${({ flex }) =>
    flex
      ? `
          display: flex;
          align-items: center;
        `
      : ''};
`;

export const CardInfoItemExtra = styled.div`
  color: #4170c4;
  font-size: 14px;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconContainer = styled.div<{
  cardType?: string;
  isIconActive?: boolean;
}>`
  position: absolute;
  display: block;
  color: #777777;
  font-size: 12px;
  right: 12px;
  z-index: 2;
  color: ${({ isIconActive }) => (isIconActive ? '#4170c4' : '')};

  svg {
    path {
      fill: ${({ isIconActive }) => (isIconActive ? 'currentColor' : ``)};
      stroke-width: 1.5px;
    }
  }
  @media only screen and (min-width: ${FIRST_BREAK_POINT}) {
    right: 24px;
  }
  ${({ cardType }) =>
    cardType === 'SMALL' &&
    `
      top: 12px;
      right: 24px;
      font-size: 20px;
    `}
`;

const Triangle = styled.div`
  position: absolute;
  bottom: -7%;
  left: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ffffff;
  margin: 0 auto;
`;

export const StyledImage = styled.img`
  height: 14px;
`;

const Icons: { [key: string]: JSX.Element } = {
  ARROW: <ChevronRightIcon />,
  HEART: <HeartIcon />,
};

export const SubUnit: FC<SubUnitProps> = ({
  title,
  lineTwoInfo,
  lineTwoExtra,
  lineThreeInfo,
  lineThreeImage,
  lineThreeImageAlt,
  lineThreeImageFallback,
  loadEagerly,
  cardType,
  media,
  seoFriendlyPath,
  fallback,
  shouldShowImage,
  icon,
  routes,
  showTriangle,
  shouldOpenNewPage,
  handleClick,
  isIconActive,
  isMobileView,
  wrapText = false,
}) => {
  const { Link } = routes;
  const PlaceholderPicture = forwardRef<HTMLPictureElement, any>(
    (props, ref) =>
      !useFeatureFlag('nextImageFeature') ? (
        <Thumbnail ref={ref} {...props}>
          <source srcSet={`${fallback}`} type="image/webp" />
          <img alt="" src={lineThreeImageFallback} />
        </Thumbnail>
      ) : (
        <ImageWrapper
          alt=""
          src={lineThreeImageFallback}
          sizes="100vw"
          width={0}
          height={0}
          style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
        />
      ),
  );

  return (
    <Link route={seoFriendlyPath} passHref legacyBehavior>
      <Wrapper
        target={shouldOpenNewPage ? '_blank' : '_self'}
        cardType={cardType}
      >
        {shouldShowImage && (
          <ColA>
            <ThumbnailBackdrop>
              <LazyImage Placeholder={PlaceholderPicture}>
                <Thumbnail>
                  <source
                    srcSet={`${safeGet(
                      media,
                      ['images', 0, 'size72x52'],
                      fallback,
                    )}`}
                    type="image/webp"
                  />
                  <img
                    src={safeGet(media, ['images', 0, 'size72x52'], fallback)}
                    alt=""
                    loading={loadEagerly ? 'eager' : 'lazy'}
                  />
                </Thumbnail>
              </LazyImage>
            </ThumbnailBackdrop>
          </ColA>
        )}
        <CardInfoWrapper>
          <StyledCol>
            <Title data-testid="sub-title">{title}</Title>
            <CardInfoContainer>
              <CardInfo
                isMobileView={isMobileView}
                data-testid={`sub-line-2-info`}
              >
                <CardInfoItem
                  isMobileView={isMobileView}
                  cardType={cardType}
                  wrapText={wrapText}
                >
                  {lineTwoInfo &&
                    lineTwoInfo.map((info, index) => {
                      return (
                        <Fragment key={`line-two-info-${index}`}>
                          {info && (
                            <>
                              {info}
                              {lineTwoInfo &&
                                index < lineTwoInfo?.length - 1 &&
                                ' · '}
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                </CardInfoItem>
                {lineTwoExtra && (
                  <CardInfoItemExtra data-testid="sub-unit-line-1-extra">
                    {lineTwoExtra}
                  </CardInfoItemExtra>
                )}
              </CardInfo>
            </CardInfoContainer>
          </StyledCol>
          <StyledCol>
            <CardInfoContainer>
              <CardInfo data-testid={`sub-line-3-info`}>
                <CardInfoItem>
                  {lineThreeInfo &&
                    lineThreeInfo.map((info, index) => {
                      return (
                        <Fragment key={`line-three-info-${index}`}>
                          {info && (
                            <>
                              {info}
                              {lineThreeInfo &&
                                index < lineThreeInfo?.length - 1 &&
                                ' · '}
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                </CardInfoItem>
                {lineThreeImage && (
                  <CardInfoItemExtra data-testid="sub-line-3-image">
                    <LazyImage Placeholder={PlaceholderPicture}>
                      <StyledImage
                        alt={lineThreeImageAlt || ''}
                        data-testid="sub-image"
                        src={lineThreeImage}
                      />
                    </LazyImage>
                  </CardInfoItemExtra>
                )}
              </CardInfo>
            </CardInfoContainer>
          </StyledCol>
        </CardInfoWrapper>
        {icon && (
          <IconContainer
            {...(handleClick && { onClick: handleClick })}
            isIconActive={isIconActive}
            cardType={cardType}
          >
            {Icons[icon]}
          </IconContainer>
        )}
        {showTriangle && <Triangle />}
      </Wrapper>
    </Link>
  );
};
