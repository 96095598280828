import ErrorBoundary from '../../helpers/components/ErrorBoundary';
import { DFPContainer } from '../Toolkit/DFPContainer/DFPContainer';

import React, {
  ComponentType,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AdSlot } from '@dsch/react-dfp';
import {
  NewButtonSizes,
  NewButtonTypes,
  SemanticButtonTypes,
} from '../../components/Toolkit/NewButton/NewButton';
import {
  CDN_URL_STATIC_DIRECTORY,
  checkCookieExists,
} from '../../helpers/utils';
import FilterButtons from './FilterButtons';
import * as S from './SearchPage.styled';
import SideColumn from './SideColumn';
import { MainFlexWrapper, SearchPageListViewResultsHeader } from './styles';
import ZeroResults from './ZeroResults';
import SortFilter from '../Filters/Sort/Sort';
import { QuickLinksRadiusFilter } from '../MultiInputTextSelect/RadiusFilter';
import { DFPScreenSizes, DFPUnitSizes } from '../Toolkit/DFPContainer/DFPSizes';
import { Listing, SECTIONS, SearchPropertyCard } from '../../types';
import {
  FilterRequestAPI,
  FilterSearchQuery,
} from '../../services/Filters/FilterTypes';
import dynamic from 'next/dynamic';
import { renderCard } from './SearchPage';
import Link from 'next/link';
import { useListingParams } from './hooks/SRPCardListingParams.hook';
import { createCard } from '../Toolkit/Card/CardFactory';
import { preparePropertyUnitData } from './services/propertyUnitService';
import { SRPResultCards } from './SRPResultCards';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  Text,
  TextFontWeight,
  TextSize,
} from '@dist-property-frontend/toolkit';
import SearchPageListViewStickyButton from './SearchPageListViewStickyButton';

const Panel: ComponentType<any> = dynamic(
  () =>
    import(
      /*webpackChunkName: "Panel-CHUNK"*/ '../../components/Toolkit/Panel/Panel'
    ).then((module) => module.Panel),
  {
    ssr: false,
  },
);

const {
  BILLBOARD,
  LEADERBOARD,
  LEADERBOARD_LARGE,
  LEADERBOARD_XL,
  MOBILE_LEADERBOARD,
  MPU_300,
} = DFPUnitSizes;

const { VIEW_DESKTOP, VIEW_MOBILE, VIEW_TABLET_PLUS } = DFPScreenSizes;

/**
 * :(
 * At index 3 (position 4) we add a property card + unit.
 * This unit goes to position 5 without affecting the index.
 * Then at index 7, we add another property card + Unit.
 * As a result the second Unit added ends up at position 10.
 */
const getUnitIdBasedOnPositionInResults = (index: 3 | 7 | 11) => {
  const adIdMap = {
    3: 5,
    7: 10,
    11: 15,
  };

  return adIdMap[index];
};

export const getSizesByIndex = (index: 3 | 7 | 11) => {
  const mappings: { [key: number]: any } = {
    3: {
      sizeMapping: [
        {
          viewport: VIEW_DESKTOP,
          sizes: [[680, 275], MPU_300, 'fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MPU_300, 'fluid'],
        },
      ],
      sizes: [[680, 275], MPU_300, 'fluid'],
    },
    7: {
      sizeMapping: [
        {
          viewport: VIEW_DESKTOP,
          sizes: [[680, 275], MPU_300, 'fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MPU_300, 'fluid'],
        },
      ],
      sizes: [[680, 275], MPU_300, 'fluid'],
    },
    11: {
      sizeMapping: [
        {
          viewport: [801, 0],
          sizes: ['fluid'],
        },
        {
          viewport: VIEW_MOBILE,
          sizes: [MOBILE_LEADERBOARD, 'fluid'],
        },
      ],
      sizes: [MOBILE_LEADERBOARD, 'fluid'],
    },
  };

  return mappings[index];
};

type SearchPageListViewProps = {
  addSessionStorage: (id: number) => void;
  debouncedOnChange: (filterObject: FilterRequestAPI) => void;
  filterAPI: FilterRequestAPI[];
  agentIdsFilter?: FilterSearchQuery;
  isLoading: boolean;
  isLoadingSection: boolean;
  isOverseasProperty: boolean;
  isSoldSection: boolean;
  hiddenFilterAPI: FilterRequestAPI[];
  isMapView?: boolean;
  isMobile: boolean;
  listings: SearchPropertyCard[];
  paging: any;
  query: any;
  queryFilters?: FilterSearchQuery[];
  onChange: (filterObject: FilterRequestAPI) => void;
  reactDFPAdsReadyToLoad: boolean;
  SearchPagePagination: ComponentType<any>;
  section: SECTIONS;
  seoH1: string;
  setSelectedListing: React.Dispatch<React.SetStateAction<Listing | null>>;
  showcaseListings: [SearchPropertyCard];
  resetFilter: (filter: FilterRequestAPI) => void;
  toggleMapView: () => void;
  toggleSavedAd: ({
    adId,
    event,
    isSaved,
    userId,
  }: {
    adId: number;
    event: React.MouseEvent;
    isSaved?: boolean;
    userId: string;
  }) => void;
  savedAds: {
    [index: number]: boolean;
  };
  isRecentSection?: boolean;
};

const SearchPageListView = (props: SearchPageListViewProps) => {
  const {
    addSessionStorage,
    agentIdsFilter,
    debouncedOnChange,
    isLoading,
    isLoadingSection,
    filterAPI,
    hiddenFilterAPI,
    isMapView,
    isMobile,
    isOverseasProperty,
    isSoldSection,
    listings,
    paging,
    query,
    queryFilters,
    onChange,
    reactDFPAdsReadyToLoad,
    resetFilter,
    savedAds,
    SearchPagePagination,
    section,
    showcaseListings,
    setSelectedListing,
    seoH1,
    toggleMapView,
    toggleSavedAd,
    isRecentSection,
  } = props;
  const [showDismissablePanel, setShowDismissablePanel] = useState(true);

  const { filteredListings } = useListingParams({
    isMapView,
    isMobile,
    isSoldSection,
    listings,
  });

  const showMapButton =
    !isOverseasProperty && !isRecentSection && !isSoldSection;

  useEffect(() => {
    if (checkCookieExists('panel-clicked') === true) {
      setShowDismissablePanel(false);
    }
  }, [showDismissablePanel]);

  const category = props.listings[0]?.listing?.category;
  /*
   * Basic ads should not be show on SRP, unless manually enabled by CS.
   * They will be displayed as Standard ads and should only appear
   * on the New Homes section.
   */
  const isBuyOrNewHomes = category && ['New Homes', 'Buy'].includes(category);

  const locationFilterCurrentValue = filterAPI?.filter(
    (filter: any) => filter.name === 'location',
  )[0]?.currentValue;

  const showRadiusQuickLinks =
    locationFilterCurrentValue &&
    locationFilterCurrentValue?.location.length === 1 &&
    listings?.length > 0 &&
    paging.totalPages === paging.currentPage;

  const handleDismissButton = () => {
    document.cookie = `panel-clicked=true;path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    setShowDismissablePanel(!showDismissablePanel);
  };

  const getMappedFilteredListings = useMemo(() => {
    const mappedFilteredListings = filteredListings.map(
      (srpCardsProps, index) => {
        const { id } = srpCardsProps;

        let SRPCard = null;

        try {
          SRPCard = renderCard(
            srpCardsProps,
            'baseCard',
            props.toggleSavedAd,
            props.savedAds,
          );
        } catch (error) {
          SRPCard = (
            <S.ErrorMessageContainer>
              Sorry, something went wrong when retrieving this ad
            </S.ErrorMessageContainer>
          );
        }

        const isPRSOrNewHome = !!(
          srpCardsProps.cardContentProps?.prsAd ||
          srpCardsProps.cardContentProps?.newHomeAd
        );

        const preparedPropertyUnitData = isPRSOrNewHome
          ? preparePropertyUnitData(
              srpCardsProps.featuredLevelFull,
              srpCardsProps.cardSubunitsProps,
            )
          : null;

        const SubunitCard = isPRSOrNewHome
          ? createCard('subunit', {
              cardSubunits: preparedPropertyUnitData.cardSubunits,
              remainingSubunits: preparedPropertyUnitData.remainingSubunits,
            })
          : null;
        return (
          <Fragment key={id}>
            <S.Result
              data-testid={`result-${id}`}
              onClick={() => addSessionStorage(id)}
            >
              <Link
                href={srpCardsProps.seoFriendlyPath}
                passHref
                legacyBehavior
              >
                <S.StyledA>
                  <SRPCard />
                </S.StyledA>
              </Link>
              {SubunitCard && <SubunitCard />}
            </S.Result>
            {isBuyOrNewHomes &&
              showDismissablePanel &&
              isMobile &&
              index === 1 && (
                <S.PanelWrapper>
                  <Panel
                    border={true}
                    buttonSize={NewButtonSizes.SMALL}
                    buttonType={NewButtonTypes.SECONDARY}
                    close={true}
                    cta="BUTTON"
                    ctaLink="https://www.advantage.daft.ie/?utm_source=Daft&utm_medium=Daft+SRP&utm_campaign=SRP+Component&utm_id=SRP+Component"
                    ctaText="Learn more"
                    id="premier-partner-srp"
                    image={`${CDN_URL_STATIC_DIRECTORY}/static/images/premier-partner/advantage-mobile.svg`}
                    onClick={() => handleDismissButton()}
                    subtitle="Talk to your agent today about the Daft Advantage and get 3X more views and enquiries for your property."
                    themeName="DEFAULT_VERTICAL_SMALL"
                    title="Give your property the Daft advantage for the best sale with no regrets."
                  />
                </S.PanelWrapper>
              )}
            {(index === 3 || index === 7 || index === 11) && (
              <DFPContainer
                AdSlot={AdSlot}
                adUnit={`daft/df_sp_search_${getUnitIdBasedOnPositionInResults(
                  index,
                )}`}
                reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
                sizeMapping={getSizesByIndex(index).sizeMapping}
                sizes={getSizesByIndex(index).sizes}
                slotId={`df_sp_search_${getUnitIdBasedOnPositionInResults(
                  index,
                )}`}
                styles={{ padding: '0 0 32px' }}
              />
            )}
          </Fragment>
        );
      },
    );
    return <>{mappedFilteredListings}</>;
  }, [filteredListings, reactDFPAdsReadyToLoad]);

  const toggleMapViewButtonRef = useRef<null | HTMLDivElement>(null);

  return (
    <>
      <DFPContainer
        AdSlot={AdSlot}
        adUnit="daft/df_sp_search_top"
        reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
        sizeMapping={[
          {
            viewport: VIEW_DESKTOP,
            sizes: [BILLBOARD, LEADERBOARD_XL, LEADERBOARD_LARGE, LEADERBOARD],
          },
          { viewport: VIEW_TABLET_PLUS, sizes: [LEADERBOARD] },
        ]}
        sizes={[BILLBOARD, LEADERBOARD, LEADERBOARD_XL, LEADERBOARD_LARGE]}
        slotId="df_sp_search_top"
      />
      <MainFlexWrapper isLoading={isLoading}>
        <S.MainColumn>
          <SearchPageListViewResultsHeader isMobileRow={!agentIdsFilter}>
            <S.StyledH1Wrapper isMobileRow={!agentIdsFilter}>
              <Text
                as="h1"
                data-testid="search-h1"
                fontWeight={TextFontWeight.SEMIBOLD}
                size={TextSize.M16}
              >
                {seoH1}
                {agentIdsFilter ? ' from' : ''}
              </Text>
            </S.StyledH1Wrapper>
            {agentIdsFilter?.values && (
              <S.FilterButtonsContainer>
                <FilterButtons
                  filters={queryFilters}
                  resetFilter={resetFilter}
                />
              </S.FilterButtonsContainer>
            )}
            <S.SearchResultsHeaderButtonContainer isMobileRow={!agentIdsFilter}>
              {showMapButton && (
                <div ref={toggleMapViewButtonRef}>
                  <Button
                    buttonSize={ButtonSizes.SMALL}
                    buttonType={ButtonTypes.SECONDARY}
                    data-testid="open-map-button"
                    data-tracking="open-mapsearch"
                    Icon={<Icon icon="MAPPIN" size={16} />}
                    onClick={toggleMapView}
                    type={SemanticButtonTypes.button}
                  >
                    Map View
                  </Button>
                </div>
              )}
              {!isRecentSection && (
                <S.SortFilterWrapper>
                  <SortFilter
                    currentValue={query['sort']}
                    id="srp-sort-listings"
                    onChange={debouncedOnChange}
                    section={section}
                    showLabel={false}
                  />
                </S.SortFilterWrapper>
              )}
            </S.SearchResultsHeaderButtonContainer>
          </SearchPageListViewResultsHeader>

          <S.SearchResults data-testid="results">
            {(isBuyOrNewHomes || isSoldSection) &&
              showDismissablePanel &&
              !isMobile && (
                <S.PanelWrapper>
                  <Panel
                    border={true}
                    buttonSize={NewButtonSizes.SMALL}
                    buttonType={NewButtonTypes.SECONDARY}
                    close={true}
                    cta="BUTTON"
                    ctaLink="https://www.advantage.daft.ie/?utm_source=Daft&utm_medium=Daft+SRP&utm_campaign=SRP+Component&utm_id=SRP+Component"
                    ctaText="Learn more"
                    id="premier-partner-srp"
                    image={`${CDN_URL_STATIC_DIRECTORY}/static/images/premier-partner/advantage-desktop.svg`}
                    onClick={() => handleDismissButton()}
                    subtitle="Talk to your agent today about the Daft Advantage and get 3X more views and enquiries for your property."
                    themeName="DEFAULT_HORIZONTAL_MEDIUM"
                    title="Give your property the Daft advantage for the best sale with no regrets."
                  />
                </S.PanelWrapper>
              )}
            {showcaseListings && showcaseListings.length > 0 && (
              <SRPResultCards
                addSessionStorage={addSessionStorage}
                cardTestID="result-showcase"
                isShowcase={true}
                listings={showcaseListings}
                savedAds={savedAds}
                setSelectedListing={setSelectedListing}
                toggleSavedAd={toggleSavedAd}
              />
            )}
            {!listings || listings.length === 0 ? (
              <ZeroResults
                filterAPI={filterAPI
                  .concat(hiddenFilterAPI)
                  .concat(queryFilters)}
                resetFilter={resetFilter}
                buttonType="SECONDARY"
              />
            ) : null}
            {getMappedFilteredListings}
            {showRadiusQuickLinks && !isSoldSection ? (
              <QuickLinksRadiusFilter
                onChange={onChange}
                locationFilterCurrentValue={locationFilterCurrentValue}
              />
            ) : null}
          </S.SearchResults>
          <ErrorBoundary
            renderFallback={() => (
              <S.ErrorMessageContainer>
                Sorry, something went wrong with the Search Page Pagination
              </S.ErrorMessageContainer>
            )}
          >
            <SearchPagePagination
              disabled={isLoading || isLoadingSection}
              onUpdate={onChange}
              paging={paging}
            />
          </ErrorBoundary>
        </S.MainColumn>
        <S.SideColumnWrapper>
          <SideColumn
            reactDFPAdsReadyToLoad={props.reactDFPAdsReadyToLoad}
            section={section}
          />
        </S.SideColumnWrapper>
        {!isSoldSection && (
          <SearchPageListViewStickyButton
            toggleMapView={toggleMapView}
            toggleMapViewButtonRef={toggleMapViewButtonRef}
          />
        )}
      </MainFlexWrapper>
    </>
  );
};

export default SearchPageListView;
